/* global USE_SERVICE_WORKER */
import './webpack-imports';
import './sentry';
import './polyfills';

import sw from './sw';
import { registerCustomElements } from './custom-elements';

import indexSlider from './inits/index-slider';
import detalCatalogSlider from './inits/detal-catalog-slider';
import { lazyload } from './inits/lazyload';
import inputFocus from './inits/input-focus';
import choices from './inits/choices';
import counter from './inits/counter';
import tabs from './inits/tabs';
import mask from './inits/mask';
import popups from './inits/lit-popup';
import burgerPopup from './inits/burger-popup';
import collapse from './inits/collapse';
import baguetteBox from 'baguettebox.js';
import filtersCollapse from './inits/filters-collapse';
import dealerPopup from './inits/dealer-popup';
import ajaxForms from './inits/ajax-forms';

import delegate from 'delegate';

let isMobile, isFiltersInited;

document.addEventListener('DOMContentLoaded', () => {
    // Forces repaint, use when really needed.
    // document.documentElement.classList.add('js-ready');
    registerCustomElements();

    indexSlider.init();
    ajaxForms.init();
    detalCatalogSlider.init();
    lazyload.update();
    inputFocus.init();
    choices.init();
    popups.init();
    mask.init();
    collapse.init();
    counter.init();
    tabs.init();
    burgerPopup.init();
    dealerPopup.init();
    baguetteBox.run('.js-detal-gallery');

    if (window.matchMedia('(max-width: 1279px)').matches) {
        isMobile = true;
    } else {
        isMobile = false;
    }

    if (isMobile) {
        filtersCollapse.init();
        isFiltersInited = true;
    }

    window.addEventListener('resize', function() {
        if (window.matchMedia('(max-width: 1279px)').matches) {
            isMobile = true;
            filtersCollapse.init();
            isFiltersInited = true;
        } else {
            isMobile = false;
            filtersCollapse.destroy();
            isFiltersInited = false;
        }
    })


    delegate(document, '.js-close-pushup', 'click', (e) => {
        const closeBtn = e.delegateTarget;
        const pushup = closeBtn.closest('.js-pushup');
        pushup.classList.add('is-hide');
        setTimeout(() => {
            if (pushup) {
                pushup.remove();
            };
        }, 600)
    });

    // Code here
});

// Service Worker
if (USE_SERVICE_WORKER) {
    window.addEventListener('load', () => sw.register());
} else {
    sw.unregister();
}
