const ANIMATION_TIME = 0;
let html = document.querySelector('html');

function init(container: Element | Document = document) {
    const tabs = Array.from(container.querySelectorAll<HTMLElement>('.tabs'));

    tabs.forEach((tab) => {
        const links = Array.from(tab.querySelectorAll<HTMLElement>('.tab__link'));
        const contents = Array.from(tab.querySelectorAll<HTMLElement>('.tab__content'));
        links.forEach((btn, i) => {
            btn.addEventListener('click', (event) => {
                event.preventDefault();
                if (btn.parentElement?.classList.contains('active')) {
                    return;
                }

                contents.forEach((el) => {
                    el.classList.remove('active');
                });

                contents[i].classList.add('active');

                links.forEach((link) => {
                    link.parentElement?.classList.remove('active');
                });

                btn.parentElement?.classList.add('active');
            });
        });
    });
}

const _module = { init };

export default _module;