import LitPopup from 'lit-popup';

import { dispatcher } from './dispatcher';
import { lockBodyScroll, unlockBodyScroll } from '../utils/lock-body-scroll';
import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';
import choices from '../inits/choices'

const map = new Map();

async function clearForm(form) {
    const [{
        clearInputs
    }, {
        clearMessages,
        clearInputErrors
    }] = await Promise.all([
        fetchAjaxFormSender(),
        fetchValidator(),
    ]);


    const inputs = Array.from(form.querySelectorAll('input[name]:not([type="submit"]):not([type="reset"]), select[name], textarea[name]'));
    const isFill = Array.from(form.querySelectorAll('.choices.is-fill'));
    isFill.forEach(item => item.classList.remove('is-fill'));
    if (form.querySelector('select')) {
        choices.getChoicesByElement(form.querySelector('select')).setChoiceByValue('');
    }
    clearInputs(inputs);
    inputs.forEach((input) => clearInputErrors(input));
    clearMessages(form);
}

function init(container = document) {
    const elements = Array.from(container.querySelectorAll('[data-lit-popup]'));
    elements.forEach((el) => {
        const name = el.dataset.litPopup;
        let wasBodyLocked = false;

        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }

        const lockScroll = () => {
            wasBodyLocked = document.body.classList.contains('no-scroll');

            if (!wasBodyLocked) {
                lockBodyScroll();
            }
        };

        const unlockScroll = () => {
            if (!wasBodyLocked) {
                unlockBodyScroll();
            }
        };

        const popup = new LitPopup(name, {
            onOpen: () => {
                lockScroll();
            },
            onOpenComplete: () => {
                dispatcher.dispatch('popup-opened', { name });
            },
            onClose: () => {
                dispatcher.dispatch('popup-closed', { name });
            },
            onCloseComplete: () => {
                let form = el.querySelector('form[data-clear-on-popup-close]');
                if (form) {
                    clearForm(form);
                }
                unlockScroll();
            },
        });

        map.set(el, popup);
    });
}

function destroy(container = document) {
    const elements = Array.from(container.querySelectorAll('[data-lit-popup]'));

    elements.forEach((el) => {
        const popup = map.get(el);

        if (popup) {
            popup.destroy();
            map.delete(el);
        }
    });
}

function getInstanceByElement(element) {
    return element ? map.get(element) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
