import LitPopup from 'lit-popup';
import barba from '@barba/core';

import { dispatcher } from './dispatcher';
import { lockBodyScroll, unlockBodyScroll } from '../utils/lock-body-scroll';

const map = new Map();

function init(container = document) {
    const el = document.querySelector('[data-lit-popup="menu"]');
    const name = el.dataset.litPopup;
    const burgerOpener = document.querySelector('.js-burger-opener');
    let wasBodyLocked = false;

    if (!name) {
        throw new Error('[lit-popup] Name should be provided.');
    }

    const lockScroll = () => {
        wasBodyLocked = document.body.classList.contains('no-scroll');

        if (!wasBodyLocked) {
            lockBodyScroll();
        }
    };

    const unlockScroll = () => {
        if (!wasBodyLocked) {
            unlockBodyScroll();
        }
    };

    const popup = new LitPopup(name, {
        onOpen: () => {
            lockScroll();
        },
        onOpenComplete: () => {
            dispatcher.dispatch('popup-opened', { name });
            setTimeout(() => {
                burgerOpener.removeAttribute('data-lit-popup-open');
                burgerOpener.setAttribute('data-lit-popup-close', name);
                burgerOpener.classList.add('is-open');
            }, 10);
        },
        onClose: () => {
            dispatcher.dispatch('popup-closed', { name });
        },
        onCloseComplete: () => {
            unlockScroll();
            setTimeout(() => {
                burgerOpener.removeAttribute('data-lit-popup-close');
                burgerOpener.setAttribute('data-lit-popup-open', name);
                burgerOpener.classList.remove('is-open');
            }, 10);
        },
    });

    map.set(el, popup);
}

function destroy(container = document) {
    const el = document.querySelector('[data-lit-popup="menu"]');

    const popup = map.get(el);

    if (popup) {
        popup.destroy();
        map.delete(el);
    }
}

function getInstanceByElement(element) {
    return element ? map.get(element) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
