import delegate from 'delegate';

function changeVal(target, changedVal) {
    let parent = target.closest('.js-counter');
    let input = parent.querySelector('.js-counter-input');
    let step = parseInt(input.dataset.step, 10) || 1;
    let max = parseInt(input.dataset.max, 10) || 1000;
    if (changedVal == 'decr') {
        if (input.value > 0) {
            input.value = input.value - step;
            $(input).trigger("change");
        }
    } else if (changedVal == 'incr') {
        if (input.value < max) {
            input.value = parseInt(input.value, 10) + step;
            $(input).trigger("change");
        }
    };
}

function init() {
    delegate('.js-counter-incr', 'click', function (e) {
        e.preventDefault();
        changeVal(e.delegateTarget, 'incr')
    }, false);
    delegate('.js-counter-decr', 'click', function (e) {
        e.preventDefault();
        changeVal(e.delegateTarget, 'decr')
    }, false);
}

export default { init }