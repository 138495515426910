import Glide from '@glidejs/glide';

let slider;
let speed = 300;
let isMobile;

function onResize() {
    isMobile = window.matchMedia('(max-width: 1279px)').matches;
}
function init(container = document) {
    isMobile = window.matchMedia('(max-width: 1279px)').matches;
    window.addEventListener('resize', onResize);
    const indexSlider = container.querySelector('.js-catalog-detal-slider');
    if (!indexSlider) {
        return;
    }
    const indexSlides = Array.from(indexSlider.querySelectorAll('.glide__slide'));
    const indexPrevBtn = container.querySelector('.js-index-prev');
    const indexNextBtn = container.querySelector('.js-index-next');
    const totalCount = container.querySelector('.js-slider-count-total');
    const curCount = container.querySelector('.js-slider-count-cur');

    function setTotal() {
        if (indexSlides.length) {
            indexSlides.length < 10 ? totalCount.innerHTML = `0${indexSlides.length}` : totalCount.innerHTML = indexSlides.length;
        } else {
            totalCount.innerHTML = '00';
        }
    }

    function setCur() {
        if (isMobile) {
            slider.index < 8 ? curCount.innerHTML = `0${slider.index + 2}` : curCount.innerHTML = slider.index + 2;
        } else {
            slider.index < 4 ? curCount.innerHTML = `0${slider.index + 6}` : curCount.innerHTML = slider.index + 6;
        }
    }

    function checkArrows() {
        if (slider.index == 0) {
            indexPrevBtn.classList.add('is-disable');
        } else {
            indexPrevBtn.classList.remove('is-disable');
        }
        if (slider.index == indexSlides.length - 6) {
            indexNextBtn.classList.add('is-disable');
        } else {
            indexNextBtn.classList.remove('is-disable');
        }
    }

    slider = new Glide(indexSlider, {
        type: 'slider',
        rewind: false,
        perView: 6,
        gap: 30,
        bound: true,
        keyboard: true,
        touchRatio: 1,
        animationDuration: speed,
        // autoplay: 5000,
        swipeThreshold: 80,
        dragThreshold: 20,
        arrows: true,
        breakpoints: {
            1279: {
              perView: 2,
              gap: 16,
            }
        }
    });

    slider.on('mount.before', function() {
        checkArrows();
        setTotal();
        setCur();
    });
    slider.on('run', function() {
        checkArrows();
        setCur();
    });

    const controls = Array.from(document.querySelectorAll('.js-index-nav [data-glide-dir]'));

    controls.forEach((item) => {
        item.addEventListener('click', (e) => {
            slider.go(`${item.getAttribute('data-glide-dir')}`);
        });
    });
    slider.mount();
}

function destroy() {
    slider.destroy(false, true);
    window.removeEventListener('resize', onResize);
}

export default { init, destroy };