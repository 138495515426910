import Glide from '@glidejs/glide';

let slider;
let speed = 300;


function init(container = document) {
    const indexSlider = container.querySelector('.js-index-slider');
    if (!indexSlider) {
        return;
    }
    const indexSlides = Array.from(indexSlider.querySelectorAll('.glide__slide'));
    const indexPrevBtn = container.querySelector('.js-index-prev');
    const indexNextBtn = container.querySelector('.js-index-next');
    const totalCount = container.querySelector('.js-slider-count-total');
    const curCount = container.querySelector('.js-slider-count-cur');

    function setTotal() {
        if (indexSlides.length) {
            indexSlides.length < 10 ? totalCount.innerHTML = `0${indexSlides.length}` : totalCount.innerHTML = indexSlides.length;
        } else {
            totalCount.innerHTML = '00';
        }
    }

    function setCur() {
        slider.index < 10 ? curCount.innerHTML = `0${slider.index + 1}` : curCount.innerHTML = slider.index + 1;
    }

    function checkArrows() {
        if (slider.index == 0) {
            indexPrevBtn.classList.add('is-disable');
        } else {
            indexPrevBtn.classList.remove('is-disable');
        }
        if (slider.index == indexSlides.length - 1) {
            indexNextBtn.classList.add('is-disable');
        } else {
            indexNextBtn.classList.remove('is-disable');
        }
    }

    slider = new Glide(indexSlider, {
        type: 'carousel',
        rewind: false,
        perView: 1,
        gap: 220,
        keyboard: true,
        touchRatio: 1,
        animationDuration: speed,
        // autoplay: 5000,
        swipeThreshold: 80,
        dragThreshold: 20,
        arrows: true,
        breakpoints: {
            800: {
                gap: 20
            }
        }
    });

    slider.on('mount.before', function() {
        checkArrows();
        setTotal();
    });
    slider.on('run', function() {
        checkArrows();
        setCur();
    });

    const controls = Array.from(document.querySelectorAll('.js-index-nav [data-glide-dir]'));

    controls.forEach((item) => {
        item.addEventListener('click', (e) => {
            slider.go(`${item.getAttribute('data-glide-dir')}`);
        });
    });
    slider.mount();
}

function destroy() {
    slider.destroy(false, true);
}

export default { init, destroy };