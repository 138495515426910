import delegate from 'delegate';

const CONTAINER_SELECTOR = '.js-filters-collapse';
const TRIGGER_SELECTOR = '.js-filters-collapse-toggler';
const EXPANDED_CLASS = 'collapse--expanded';
const ANIMATION_TIME = 400;
let delegation: any;

function onClick(event: any): void {
    const parent = event.delegateTarget.closest(CONTAINER_SELECTOR);
    const isExpanded = event.delegateTarget.getAttribute('aria-expanded') === 'true';
    event.delegateTarget.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');

    if (parent) {
        parent.classList[isExpanded ? 'remove' : 'add'](EXPANDED_CLASS);
    }
}

function init(container: Element | Document = document) {
    const triggers = Array.from(container.querySelectorAll(TRIGGER_SELECTOR));
    triggers.forEach((trigger) => {
        const parent = trigger.closest(CONTAINER_SELECTOR);
        trigger.setAttribute('aria-expanded', parent && parent.classList.contains(EXPANDED_CLASS) ? 'true' : 'false');
    });
    delegation = delegate(document, TRIGGER_SELECTOR, 'click', onClick);
}

function destroy() {
    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

const module = { init, destroy };

export default module;
