import Choices from 'choices.js';

const selector = '.js-choices';
const INITIALIZED_CLASS = 'choices-initialized';
const map = new WeakMap();

function init(container = document) {
    const elements = Array.from(container.querySelectorAll(selector));
    if (elements.length > 0) {
        elements.forEach((el) => {
            if (el.classList.contains(INITIALIZED_CLASS)) {
                return;
            }

            if (el instanceof HTMLSelectElement) {
                const choices = new Choices(el, {
                    searchEnabled: el.classList.contains('js-select-search'),
                    itemSelectText: '',
                    searchPlaceholderValue: 'Enter your country',
                    shouldSort: false,
                    position: 'bottom',
                });

                const classes = el.className.split(' ');
                classes.forEach((className) => {
                    choices.containerOuter.element.classList.add(className);
                });

                choices.passedElement.element.addEventListener('showDropdown', () => {
                    const dropdown = choices.dropdown.element;
                    const dropdownWidth = dropdown.offsetWidth;
                    const dropdownRect = dropdown.getBoundingClientRect();
                    const { left } = dropdownRect;
                    const diff = left + dropdownWidth - window.innerWidth;

                    if (diff > 0) {
                        dropdown.style.left = `${-(diff + 30)}px`;
                    }
                });

                choices.passedElement.element.addEventListener('change', (e) => {
                    e.target.value !== ''
                        ? choices.containerOuter.element.classList.add('is-fill')
                        : choices.containerOuter.element.classList.remove('is-fill');
                });

                map.set(el, choices);
                el.classList.add(INITIALIZED_CLASS);
            }
        });
    }
}

function destroy(container = document) {
    const elements = Array.from(container.querySelectorAll(selector));
    elements.forEach((el) => {
        const choices = map.get(el);

        if (choices) {
            choices.destroy();
            map.delete(el);
            el.classList.remove(INITIALIZED_CLASS);
        }
    });
}

function getChoicesByElement(element) {
    return map.get(element);
}

window.choicesReinit = init;

const _module = { init, destroy, getChoicesByElement };

export default _module;
