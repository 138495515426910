function init() {
    const dealerPopup = document.querySelector('.js-dealer');
    if (dealerPopup) {
        dealerPopup.addEventListener('open', (e) => {
            const trigger = e.detail.triggerElement;
            const triggerDealer = trigger.closest('.js-dealer-wrapper');

            const triggerTitle = triggerDealer.querySelector('.js-dealer-title');
            const triggerPicSrc = triggerDealer.querySelector('.js-dealer-pic').getAttribute('src');
            const triggerAddress = triggerDealer.dataset.address;
            const triggerPhoneOne = triggerDealer.dataset.phoneOne;
            const triggerPhoneTwo = triggerDealer.dataset.phoneTwo;
            const triggerEmail = triggerDealer.dataset.email;
            const triggerSite = triggerDealer.dataset.site;

            const dealerPopupTitle = dealerPopup.querySelector('.js-dealer-popup-title');
            const dealerPopupPic = dealerPopup.querySelector('.js-dealer-popup-pic');
            const dealerPopupAddress = dealerPopup.querySelector('.js-dealer-popup-address');
            const dealerPopupPhoneOne = dealerPopup.querySelector('.js-dealer-popup-phone-1');
            const dealerPopupPhoneTwo = dealerPopup.querySelector('.js-dealer-popup-phone-2');
            const dealerPopupEmail = dealerPopup.querySelector('.js-dealer-popup-email');
            const dealerPopupSite = dealerPopup.querySelector('.js-dealer-popup-site');

            dealerPopupTitle.innerHTML = triggerTitle.innerHTML;
            dealerPopupPic.setAttribute('src', triggerPicSrc);
            dealerPopupAddress.innerHTML = triggerAddress;
            dealerPopupPhoneOne.innerHTML = triggerPhoneOne;
            dealerPopupPhoneTwo.innerHTML = triggerPhoneTwo;
            dealerPopupEmail.innerHTML = triggerEmail;
            dealerPopupSite.innerHTML = triggerSite;
        });
    }
}

export default { init };
