import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';
import popups from '../inits/lit-popup';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();

const showSuccessMessage = (form: HTMLFormElement, message = '') => {
    const successMessage = form.querySelector('.js-form-message__success');

    if (successMessage) {
        successMessage.textContent = message;
    }
};

const showFailureMessage = (form: HTMLFormElement, message = '') => {
    const failureMessage = form.querySelector('.js-form-message__failure');

    if (failureMessage) {
        failureMessage.textContent = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    if (messagesContainer) {
        messagesContainer.classList.remove('form-messages--hidden');
    }
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector('input.js-form__check-val') as HTMLInputElement | null;
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];
    if (forms.length > 0) {
        const [{ default: createFormSender }, { default: createValidator, clearMessages }] = await Promise.all([
            fetchAjaxFormSender(),
            fetchValidator(),
        ]);

        forms.forEach((form) => {
            const validator = createValidator(form);
            const sender = createFormSender(form, {
                shouldClearInputs: true,
                onBeforeSend: () => {
                    clearAntispamInput(form);
                },
                onSuccess: (response) => {
                    if (response.order.REDIRECT_URL) {
                        window.location.href = response.order.REDIRECT_URL;
                    }
                    if (response.message) {
                        const pushup = form.querySelector('.js-pushup');
                        const formMessage = form.querySelector('.js-form-message');
                        if (formMessage && pushup) {
                            pushup.innerHTML = response.message;
                            formMessage.classList.add('is-show');
                            setTimeout(() => {
                                formMessage.classList.add('is-hide');
                                setTimeout(() => {
                                    formMessage.classList.remove('is-show');
                                }, 600);
                            }, 4400);
                        }
                    }
                    const formPopupWrapper = form.closest('.lit-popup');
                    if (formPopupWrapper && formPopupWrapper.classList.contains('lit-popup--opened')) {
                        const popup = popups.getInstanceByElement(formPopupWrapper);
                        popup.close();
                    }
                    form.classList.remove('is-load');
                },
                onError: () => {
                    showFailureMessage(form, 'An error occurred when submitting the form');
                    form.classList.remove('is-load');
                },
                onComplete: () => {
                    clearMessages(form);
                    showFormMessages(form);
                    form.classList.remove('is-load');
                },
            });
            map.set(form, sender);

            form.addEventListener('submit', (event) => {
                event.preventDefault();
                const isFormValid = validator.validate();
                if (isFormValid) {
                    form.classList.add('is-load');
                    sender.send();
                }
            });
        });
    }
}

function destroy(container: Element | Document = document) {
    //
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
